import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Workflow that just works",
    paragraph:
      "Our Automated workflow keeps your company doing what it does best, and lets us worry about creating lead lists, generating phone calls, and bringing your prospects directly to you",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Website - Single Page Web Application
                </div>
                <h3 className="mt-0 mb-12">
                  Get Online with a Website built just for your Business
                </h3>
                {/* <p className="m-0"> */}
                <div className="m-0">
                  Aside from being stunningly beautiful, quick and efficient --
                  Your website will include:
                  <p>
                    - Your <u>OWN</u> Domain Name/URL
                  </p>
                  <p>
                    - Full MOBILE compatibility. We build our websites with a
                    "Mobile First" philosophy.
                  </p>
                  <p>
                    {" "}
                    - Cutting Edge Technology with best practices (ask for more
                    info)
                  </p>
                </div>
                {/*                   
                </p> */}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/website-example.png")}
                  alt="Website Example"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Google My Business
                </div>
                <h3 className="mt-0 mb-12">
                  Get noticed with Google My Business
                </h3>
                <div className="m-0">
                  We put your Digital Business Card on Google. Among optimizing,
                  updating, and creating this card we also:
                  <div>
                    - Geo-Tag/Location-Tag the listed photos for your company to
                    bring local Google searches to you!
                  </div>
                  <div>
                    {" "}
                    - Register Business and New Website with Google to increase
                    Search Optimization
                  </div>
                  <div>
                    {" "}
                    - Deploy Forwarding Phone Number, to better track who's
                    calling - and - from where!
                  </div>
                  <div>
                    - Get your Business on the map! We register your Business
                    address on Google Maps{" "}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/stgopera.png")}
                  alt="St. George Opera"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Automated Lead Funnels
                </div>
                <h3 className="mt-0 mb-12">Let Automation do the work!</h3>
                <div className="m-0">
                  We take your Lead Data, collected from your new website, and
                  "plug it in" where you need it. Our basic service provides:
                  <div>
                    - Automated Lead funnel - All form data collected updates
                    your spreadsheets (or CRM)
                  </div>
                  <div>
                    - Email and Text notifications with new Lead Data for
                    quicker acquisition!
                  </div>
                  <div>
                    (extra) Auto-Dial data automatically, and ring back to your
                    phone! No hand dialing!
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/zapier-lead-funnel.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Search Engine Optimization
                </div>
                <h3 className="mt-0 mb-12">
                  Get on the Front Page of Google in your Area!
                </h3>

                <div className="m-0">
                  Out of the box, your website will be fully Search Engine
                  optimized! This means that we will:
                  <div>
                    - Target key words in your Business' niche (i.e, "sell home
                    today, realtor near me") with descriptive META tags.
                  </div>
                  <div>
                    - Follow Google's coding practices (Progressive Web
                    Application)
                  </div>
                  <div>
                    - (extra) Extensive backlinking for rapid increase in Google
                    rank
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/meta-tag-example.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
