import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const zapierHook = "https://hooks.zapier.com/hooks/catch/9225569/ocj0b4c/";

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [person, setPerson] = useState({
    name: "",
    email: "",
    number: "",
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPerson({ ...person, [name]: value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (person.name && person.email && person.number) {
      setPerson({ name: "", email: "", number: "" });
      fetch(zapierHook, {
        method: "POST",
        body: JSON.stringify({ person }),
      })
        .then(() => setFormSubmitted(true))
        .catch((err) => {
          alert(
            "There was an error, please contact me at curtisknudson@gmail.com"
          );
        });
    } else {
      alert("Your information is required to submit!");
    }
  };

  const formHtml = (
    <div className={innerClasses}>
      <div className="cta-slogan">
        <h3 className="m-0">Schedule a Phone Call today!</h3>
      </div>
      <div className="cta-action">
        <form id="schedule-call-form">
          <Input
            required
            type="email"
            placeholder="EMAIL"
            name="email"
            value={person.email}
            onChange={handleChange}
          ></Input>
          <Input
            required
            type="text"
            placeholder="NAME"
            name="name"
            value={person.name}
            onChange={handleChange}
          ></Input>
          <Input
            required
            type="tel"
            placeholder="NUMBER"
            name="number"
            value={person.number}
            onChange={handleChange}
          ></Input>
          <Button
            type="submit"
            color="transparent"
            wide={true}
            onClick={handleClick}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );

  const formThankYou = (
    <div className={innerClasses}>
      <h1 id="schedule-call-form">Thank you! You will hear from us soon!</h1>
    </div>
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">{formSubmitted ? formThankYou : formHtml}</div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
