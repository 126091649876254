import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Our Complete Product (I.e, and so much more...)",
    paragraph:
      "Our comprehensive product list below is not limited. We can do almost anything digital, so let us know what you have in mind",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="developer-icon"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Developed From Ground Up!</h4>
                  <p className="m-0 text-sm">
                    Our websites are custom-coded and designed just for you!.
                    This includes:
                  </p>
                  <ul style={{"list-style-type": "none"}}>
                    <li>Fully optimized mobile and desktop websites</li>
                    <li>Your own website domain!</li>
                    <li>
                      No Monthly WordPress expenses and the first year of
                      hosting is free!
                    </li>
                    <li>Extremely fast!</li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Google My Business</h4>
                  <p className="m-0 text-sm">
                    We bring your Company to Google with "Google My Business".
                    This will include:
                  </p>
                  <ul style={{"list-style-type": "none"}}>
                    <li>Forwarding Phone Number</li>
                    <li>
                      Location/Geo Tagged photos (increases Google Rank in area)
                    </li>
                    <li>Newly Created Website Link</li>
                    <li>
                      Campaign to generate reviews from past clients! (by
                      request)
                    </li>
                    <li>
                      (By request) Create an E-mail for your company
                      (johndoe@yourcompany.com)
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Search Engine Optimizaiton</h4>
                  <p className="m-0 text-sm">
                    Whether you live in a big city or a small town, we'll make
                    your company the front of targeted search results in your
                    area. This will include industry secrets, as well as:
                  </p>
                  <ul style={{"list-style-type": "none"}}>
                    <li>Google Search Console registration</li>
                    <li>HTML5 MetaTags</li>
                    <li>Keyword Optimization throughout website</li>
                    <li>(extra) Backlinking</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Landing Pages to Lead Lists</h4>
                  <p className="m-0 text-sm">
                    Your new website will include a landing page for lead
                    generation. A landing page is where future-customers will be
                    directed if they want more information on your company. This
                    includes:
                  </p>
                  <ul style={{"list-style-type": "none"}}>
                    <li>Name, E-mail, Phone Number form</li>
                    <li>
                      {" "}
                      (free) Dyanamically Generated Google Sheets with secure
                      list of all Lead data.
                    </li>
                    <li>
                      Lead Notifications to E-mail!<br></br> (e.g, Lead:
                      <br></br>
                      Name: John Doe <br></br>E-mail: johndoe@gmail.com
                      <br></br>
                      Number: (555)555-5555)
                    </li>
                    <li>(extra) Dynamically updated CRM (e.g, HubSpot)</li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Social Media (Extra)</h4>
                  <p className="m-0 text-sm">
                    In this day and age, Social Media is often the best way for
                    customers to contact you! With our Social Media Package we
                    will:
                  </p>
                  <ul style={{"list-style-type": "none"}}>
                    <li>
                      Build and design a business page for your company on
                      Facebook and Instagram, to mutually compliment your
                      presence on Google.
                    </li>
                    <li>Keep and Maintain engaging content</li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Data-Driven Advertisement</h4>
                  <p className="m-0 text-sm">
                    Your website will be equipped out of the box with access to
                    "Google Analytics". Together we will create advertising
                    campaigns based on Analytics data. This includes:
                  </p>
                  <ul style={{"list-style-type": "none"}}>
                    <li>Complete Management of advertising campaigns.</li>
                    <li>
                      Designing Lead Funnels and Data Sets (e.g, How many leads
                      are leaving website in first 30 seconds? What demographic
                      is staying longest? )
                    </li>
                  </ul>
                  <p>
                    This service is offered free for the first 3 months of
                    website purchase, assuming you pay cost of advertising.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Our Fee</h4>
                  <p className="m-0 text-sm">
                    Midgard Marketing sets out to provide a dynamic online
                    presence, with NO MONTHLY FEES on our standard product. Our
                    Standard Product includes everything listed above that
                    doesn't say (extra). Our Flat Fee is $4,999, and is on a
                    quote-only basis for anything else.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
